import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputStyle } from '../shared/form-control/Input';
import RadioButton from '../shared/form-control/RadioButton';
import { Option } from '../Option';
import FilterIcon from '../shared/icon/FilterIcon';
import PickListService from '../../services/PickListService';
import ObjectUtils from '../../utils/ObjectUtils';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import LanguageUtils from '../../utils/LanguageUtils';
import { EventSystem } from '../../events/EventSystem';
import { PickListInfo } from '../../models/Picklist';
import RootPortal from '../shared/RootPortal';
import { useModal } from '../../contexts/ModalContext';
import { SupportedLanguage } from '../../types/Languages';

export enum ChartDataSourceType {
  AssetType,
  AssociatedClients,
  Modules,
  Users,
  PickList,
}

type ChartDataSourceSelectorProps = {
  mode: 'asset' | 'document';
  onChange: (type: ChartDataSourceType, value: string, text: string) => void;
  selectedSource?: string | ChartDataSourceType;
};

const ChartDataSourceSelector: FC<ChartDataSourceSelectorProps> = (props) => {
  const { onChange, selectedSource = ChartDataSourceType.AssetType, mode } = props;
  const { t } = useTranslation(['client-dashboard', 'common']);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLSpanElement>(null);
  const [picklists, setPicklists] = useState<Option<string, boolean>[]>([]);
  const { open: modelOpen } = useModal();
  const defaultOptions = useMemo(
    () => [
      { id: ChartDataSourceType.AssetType, text: t(`client-dashboard:charts.data-source.static.${mode}-type`), value: true },
      { id: ChartDataSourceType.AssociatedClients, text: t('client-dashboard:charts.data-source.static.associates'), value: false },
      { id: ChartDataSourceType.Modules, text: t('client-dashboard:charts.data-source.static.modules'), value: false },
      { id: ChartDataSourceType.Users, text: t('client-dashboard:charts.data-source.static.users'), value: false },
    ],
    [mode, t],
  );
  const [options, setOptions] = useState<Option<ChartDataSourceType, boolean>[]>(defaultOptions);
  const [searchTerm, setSearchTerm] = useState('');

  const client = useRecoilValue(currentClientAtom);

  const [picklistSource, setPicklistSource] = useState<PickListInfo[]>([]);

  useEffect(() => {
    setOptions(defaultOptions);
  }, [defaultOptions]);

  useEffect(() => {
    PickListService.getAll({ pageNumber: 1, pageSize: 500, clientId: client?.id }).then((res) => {
      setPicklistSource(res.data);
      setPicklists(
        res.data.map((picklist) => ({
          id: picklist.id,
          text: LanguageUtils.getTranslation('name', picklist.translations),
          value: false,
        })),
      );
    });
  }, [client?.id]);

  useEffect(
    function langaugeChanged() {
      const handler = (languageCode: SupportedLanguage) =>
        setPicklists(
          picklistSource.map((picklist) => ({
            id: picklist.id,
            text: LanguageUtils.getTranslation('name', picklist.translations, languageCode),
            value: false,
          })),
        );

      EventSystem.listen('language-changed', handler);
      return () => {
        EventSystem.stopListening('language-changed', handler);
      };
    },
    [picklistSource],
  );

  // #region Menu Layout

  useEffect(() => {
    const mouseDownListener = (e: MouseEvent): void => {
      if (
        menuOpen &&
        menuRef.current &&
        !(menuRef.current.contains(e.target as Node) || menuRef.current === e.target) &&
        triggerRef.current &&
        !(triggerRef.current.contains(e.target as Node) || triggerRef.current === e.target)
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', mouseDownListener);

    return () => {
      document.removeEventListener('mousedown', mouseDownListener);
    };
  }, [menuOpen]);

  // #endregion

  const filteredDefaults = useMemo(() => {
    return options
      .filter((item) => item.text.toLocaleLowerCase().indexOf(searchTerm) > -1)
      .map((option) => ({ ...option, value: option.id === selectedSource }));
  }, [options, searchTerm, selectedSource]);

  const filteredPicklists = useMemo(() => {
    return picklists
      .filter((item) => item.text.toLocaleLowerCase().indexOf(searchTerm) > -1)
      .map((option) => ({ ...option, value: option.id === selectedSource }));
  }, [picklists, searchTerm, selectedSource]);

  const onSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchPhrase = event.target.value;
    setSearchTerm(searchPhrase);
  };

  const onDefaultsRadioButtonChanged = (index: number, type: ChartDataSourceType): void => {
    let newOptions = ObjectUtils.DeepClone(options);

    newOptions = newOptions.map((option) => ({ ...option, value: false }));
    newOptions[index].value = true;

    setPicklists(ObjectUtils.DeepClone(picklists).map((option) => ({ ...option, value: false })));

    setOptions(newOptions);

    onChange(type, type.toString(), newOptions[index].text);

    setMenuOpen(false);
  };

  const onPicklistRadioButtonChanged = (index: number, id: string): void => {
    let newPicklists = ObjectUtils.DeepClone(picklists);

    newPicklists = newPicklists.map((option) => ({ ...option, value: false }));
    newPicklists[index].value = true;

    setOptions(ObjectUtils.DeepClone(options).map((option) => ({ ...option, value: false })));

    setPicklists(newPicklists);

    onChange(ChartDataSourceType.PickList, id, newPicklists[index].text);

    setMenuOpen(false);
  };

  return (
    <div className="relative flex items-center gap-2">
      <span
        className="cursor-pointer"
        ref={triggerRef}
        onClick={() => {
          if (menuRef.current !== null) {
            const triggerBound = triggerRef.current?.getBoundingClientRect();
            menuRef.current.style.left = (triggerBound?.right || 0) - menuRef.current.offsetWidth + 'px';
            menuRef.current.style.top = (triggerBound?.top || 0) + window.scrollY + (triggerBound?.height || 0) + 'px';
          }
          setMenuOpen(!menuOpen);
        }}
      >
        <FilterIcon className="text-primary-1" />
      </span>
      <RootPortal elementId="popover-root">
        <div
          ref={menuRef}
          role="menu"
          aria-orientation="vertical"
          className={`border-gray-5 rounded-dpm-sm absolute mt-2 w-96 border-2 bg-white px-2 py-2 shadow-xl ${menuOpen ? '' : 'invisible'} ${
            modelOpen && 'z-popover'
          }`}
          data-cy="filter-menu"
          style={{ left: 0, top: 0 }}
        >
          <Input style={InputStyle.MINIMAL} value={searchTerm} onChange={onSearchTextChange} placeholder={t('common:list.filter.search')} />
          <div className="p-1">
            <div className="max-h-72 min-w-full max-w-0 overflow-y-auto">
              {filteredDefaults.map((option, i) => (
                <RadioButton
                  key={option.id}
                  value={option.value}
                  label={option.text}
                  multi={false}
                  onChange={() => onDefaultsRadioButtonChanged(i, option.id)}
                  role="menuitem"
                />
              ))}
              <span className="font-medium">{t('client-dashboard:charts.data-source.picklist')}</span>
              {filteredPicklists.map((option, i) => (
                <RadioButton
                  key={option.id}
                  value={option.value}
                  label={option.text}
                  multi={false}
                  onChange={() => onPicklistRadioButtonChanged(i, option.id)}
                  role="menuitem"
                />
              ))}
            </div>
          </div>
        </div>
      </RootPortal>
    </div>
  );
};

export default ChartDataSourceSelector;
