import { ApiResponse } from '../models/ApiResponse';
import { ApplicationLocation } from '../models/ApplicationLocation';
import { AssetChartData, AssetOverTimeChartData } from '../models/AssetChartData';
import { FormType } from '../models/FormTypes';
import BaseService from './BaseService';

export default class ReportingService extends BaseService {
  public static getApplicationLocations(formTypes: (typeof FormType)[keyof typeof FormType][]): Promise<ApiResponse<ApplicationLocation[]>> {
    return this.get('/v1/reports/locations', { params: { formTypes } });
  }

  public static getAssetChartData(date: Date, formTypes: (typeof FormType)[keyof typeof FormType][]): Promise<ApiResponse<AssetChartData[]>> {
    return this.get('/v1/reports/asset-charts', { params: { date, formTypes } });
  }

  public static getAssetChartDataForPickList(
    pickListId: string,
    formTypes: (typeof FormType)[keyof typeof FormType][],
  ): Promise<ApiResponse<AssetChartData[]>> {
    return this.get(`/v1/reports/asset-charts/picklists/${pickListId}`, { params: { formTypes } });
  }

  public static getAssetChartDataForModules(formTypes: (typeof FormType)[keyof typeof FormType][]): Promise<ApiResponse<AssetChartData[]>> {
    return this.get('/v1/reports/asset-charts/modules', { params: { formTypes } });
  }

  public static getAssetChartDataForUsers(formTypes: (typeof FormType)[keyof typeof FormType][]): Promise<ApiResponse<AssetChartData[]>> {
    return this.get('/v1/reports/asset-charts/users', { params: { formTypes } });
  }

  public static getAssetsRiskOverTimeChartData(
    formTypes: (typeof FormType)[keyof typeof FormType][],
  ): Promise<ApiResponse<AssetOverTimeChartData[]>> {
    return this.get('/v1/reports/asset-charts/overtime', { params: { formTypes } });
  }

  public static getAssetChartDataForAssociatedClients(formTypes: (typeof FormType)[keyof typeof FormType][]): Promise<ApiResponse<AssetChartData[]>> {
    return this.get('/v1/reports/asset-charts/affiliates', { params: { formTypes } });
  }

  public static getLastRunTimestamp(): Promise<ApiResponse<string>> {
    return this.get('/v1/reports/timestamp');
  }
}
