import { useEffect, useRef, useState } from 'react';
import { FCWithChildren } from '../../types/FCWithChildren';

const ExpandedChartWrapper: FCWithChildren<{ labelCount: number }> = (props) => {
  const { labelCount, children } = props;
  const outerChartRef = useRef<HTMLDivElement>(null);
  const [chartheight, setChartHeight] = useState('100%');
  useEffect(() => {
    if (outerChartRef.current) {
      const height = labelCount * 30;
      if (height > outerChartRef.current?.clientHeight) {
        setChartHeight(`${height}px`);
      } else {
        setChartHeight('100%');
      }
    }
  }, [labelCount]);

  return (
    <div ref={outerChartRef} className="h-full overflow-y-auto pt-4">
      <div style={{ height: chartheight }}>{children}</div>
    </div>
  );
};

export default ExpandedChartWrapper;
