/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ChartOptions,
  Tooltip as TooltipPlugin,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  BarController,
  LineController,
} from 'chart.js';
import { colourRgb } from '../../../utils/ColourUtils';
import { nextTick } from '../../../utils/ReactUtils';
import StringUtils from '../../../utils/StringUtils';
import { ChartDataSet } from './ChartDataSet';

ChartJS.register([CategoryScale, LinearScale, TooltipPlugin, BarElement, PointElement, LineElement, BarController, LineController]);

export enum BarChartType {
  HORIZONTAL,
  VERTICAL,
}

type BarChartProps = {
  data: ChartDataSet;
  type?: BarChartType;
  hideLegend?: boolean;
  stacked?: boolean;
  limit?: number;
  filterZeros?: boolean;
  /** Default true */
  truncateYlabels?: boolean;
};

const BarChart: FC<BarChartProps> = (props) => {
  const { data, hideLegend, type = BarChartType.VERTICAL, stacked = false, limit = 0, filterZeros, truncateYlabels = true } = props;
  const [hidden, setHidden] = useState(false);
  const [animate, setAnimate] = useState(true);

  const noLegend = useMemo(() => (hideLegend ? hideLegend : data.datasets.length < 2), [data.datasets.length, hideLegend]);

  const chartData = useMemo(() => {
    // Can't deep clone this, because ChartJS injects its own properties into it
    let temp = { ...data };
    temp.datasets = data.datasets.map((x) => ({ ...x, data: [...x.data] }));

    if (filterZeros) {
      const toRemoveIndexs: number[] = [];
      for (let labelIndex = 0; labelIndex < data.labels.length; labelIndex++) {
        const allZeros = data.datasets.every((dataset) => dataset.data[labelIndex] === 0);
        if (allZeros) {
          toRemoveIndexs.push(labelIndex);
        }
      }

      temp.labels = temp.labels.filter((_, i) => toRemoveIndexs.indexOf(i) === -1);
      for (const dataSetIndex in temp.datasets) {
        temp.datasets[dataSetIndex].data = temp.datasets[dataSetIndex].data.filter((_, i) => toRemoveIndexs.indexOf(i) === -1);
      }
    }

    if (limit !== 0) {
      const labels = limit < 0 ? temp.labels.slice(limit, temp.labels.length) : temp.labels.slice(0, limit);
      const datasets =
        limit < 0
          ? temp.datasets.map((dataset) => ({ ...dataset, data: dataset.data.slice(limit, dataset.data.length) }))
          : temp.datasets.map((dataset) => ({ ...dataset, data: dataset.data.slice(0, limit) }));
      temp = { ...temp, labels: [...labels], datasets: [...datasets] };
    }

    return temp;
  }, [data, filterZeros, limit]);

  const resize = useCallback(() => {
    setHidden(true);
    nextTick(() => setHidden(false));
  }, []);

  useEffect(() => {
    const handler = () => {
      setAnimate(false);
      resize();
    };

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [resize]);

  const options = useMemo<ChartOptions<'bar'>>(
    () => ({
      indexAxis: type === BarChartType.VERTICAL ? 'x' : 'y',
      animation: {
        duration: animate ? 1000 : 0,
      },
      maintainAspectRatio: false,
      responsive: true,
      tooltips: { enabled: true },
      legend: {
        labels: {
          boxWidth: noLegend ? 0 : 8,
          usePointStyle: true,
          fontColor: noLegend ? 'transparent' : colourRgb('text-2'),
        },
      },
      scales: {
        y: {
          stacked: stacked,
          gridLines: { drawTicks: false, drawBorder: false, offsetGridLines: true, zeroLineColor: colourRgb('gray-5'), color: colourRgb('gray-5') },
          ticks: {
            padding: 10,
            beginAtZero: true,
            precision: 0,
            callback: function (value) {
              return truncateYlabels ? StringUtils.truncate(`${this.getLabelForValue(value as number)}`, 30) : value;
            },
          },
        },
        x: {
          stacked: stacked,
          gridLines: { drawTicks: false, drawBorder: false, zeroLineColor: colourRgb('gray-5'), color: colourRgb('gray-5') },
          ticks: {
            padding: 10,
            beginAtZero: true,
            precision: 0,
          },
          scaleLabel: { display: !!data.xLabel, labelString: data.xLabel },
        },
      },
    }),
    [animate, data.xLabel, noLegend, stacked, truncateYlabels, type],
  );

  if (hidden) {
    return null;
  }

  return (
    <div className="h-full max-h-full">
      <Chart type="bar" data={chartData as any} options={options} />
    </div>
  );
};

export default BarChart;
